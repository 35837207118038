<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Add New Particular case</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="item.label"
              outlined
              dense
              :rules="[rules.label_required]"
              :error-messages="errorMessages"
              :label="$t('Libellé')"
              :placeholder="$t('Libellé')"
              hide-details="auto"
            ></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">

          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="resetItem"
          >
            {{ $t('Annuler') }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>

        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
//import controller from './Controller'

export default {
  name: "ParticularCaseForm",

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const errorMessages = ref('')
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingSources = ref(false)
    const rules = {
      label_required: true,
    }
    const validate = () => {
      form.value.validate()
    }

    /*const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })*/
    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        if (item.value.id === null || item.value.id === undefined) {
          store.dispatch('particularCase/addParticularCase', {label: item.value.label})
            .then(response => {
              loading.value = false
              emit('refetch-data')
            }).catch(error => {
            validate()
            errorMessages.value = error.response.data.message
            rules.label_required = error.response.data.errors !== undefined && error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : ''

            loading.value = false
            console.log('error', error)
            /*if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }*/
          })
        } else{
          store.dispatch('particularCase/updateParticularCase', item.value)
            .then(response => {
              loading.value = false
              emit('refetch-data')
            }).catch(error => {
            validate()
            errorMessages.value = error.response.data.message
            rules.label_required = error.response.data.errors !== undefined && error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : ''

            loading.value = false
            console.log('error', error)
            /*if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }*/
          })
        }
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      rules,
      resetItem,
      form,
      isLoadingSources,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
